/**
 *	Styles
 */

@import 'settings/index';
@import 'tools/index';
@import 'generic/index';
@import 'elements/index';
@import 'objects/index';
@import 'components/index';
@import 'vendors/index';
@import 'utilities/index';
@import 'node_modules/bootstrap/scss/utilities/api';
@import 'custom/index';

.editable-input {
	border: none;
	outline: none;
	border-radius: 5px;

	&:focus {
		border: 1px solid rgb(133, 133, 133);
	}
}

.dropdown-menu {
	max-height: 300px;
	overflow-y: auto;
}

.rnc__notification-timer {
	transform: rotate(180deg);
}

.round-img {
	border-radius: 10px;
}

.ScrollStyle {
	max-height: 150px;
	overflow-y: scroll;
}

.option-card {
	background: #d8f6f2;
	border-radius: 14px;
	padding: 13px;
}

.cropped-img {
	color: white;
	border-radius: 11px;
	width: 400px;
	height: 200px;
	background-color: #f8f9fa;
}
